<template>
    <div class="login-wrap">
        <div class="login-body">
            <div class="cent">
                <img src="../assets/images/xg/LOGO.png" class="xg" />
                <p style="margin-top: 30px;">忘记密码</p>
            </div>
          <van-form @submit="onSubmit" style="margin-top: 20px;" class="reist">
 <van-field v-model="mobile" name="mobile" maxlength="11" label-width="4rem" placeholder="请输入您的手机号码"
      style="margin-bottom: 20px;" />
            <van-field v-model="password" :type="passwordShow ? 'text' : 'password'" name="password" label-width="4rem"
              placeholder="请设置您的登录密码(最少6位)" style="margin-bottom: 20px;" />

            <van-field v-model="smsCodeNum" center clearable placeholder="请输入短信验证码">
              <template #button>
                <van-button  @click="getSmsCode" type="primary" class="smsbtn">
            获取
                </van-button>
              </template>
            </van-field>
          <van-radio-group v-model="checked" style="margin-bottom: 20px;">
              <van-radio name="1" checked-color="rgba(68, 159, 46, 1)" style="font-size: 16px;">我已阅读并同意<span
                      style="color: red;">《隐私政策》</span></van-radio>
          </van-radio-group>
            <van-button block type="primary" native-type="submit" style="margin-bottom: 20px;background-color: green;" class="success">
              完成
            </van-button>
          </van-form>


            <div class="down mt20" style="text-align: center; margin-bottom: 20px;">
                <!-- <span class="text-warning" style="float: left" @click="download"></span> -->
                <span>
                    <span style="font-size: 14px;">已有账号，去<span style="color: rgba(68, 159, 46, 1);font-size: 14px;"
                            @click="loginss">登录</span></span>
                    <!-- <span class="text-warning">立即注册</span> -->
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { smsCode, getCaptcha,getCode,updatePassword } from "@/utils/api.js";
export default {
    name: "forget",
    data() {
        return {
            mobile: "", //手机号
            password: "", //密码
            payPassword: "", //重复密码
            code: "", //邀请码
            open_id: 123456,
            smsCodeNum: "",
            smsCodeTime: 120,
            smsCodeText: "获取验证码",
            smsCodeInterval: null,
            captchaData: {},
            checked: '1',
            passwordShow: false,
            passwordShowtwo: false,
        };
    },
    created() {
        this.getCaptcha();
        this.code = this.$route.query.yqm;
    },
    methods: {
        getCaptcha() {
            getCaptcha().then((res) => {
                this.captchaData = res.data;
            });
        },
        onSubmit() {
            if (this.mobile === "") {
                this.$toast({
                    message: "请输入手机号",
                    icon: 'fail',
                    className: 'noticeWidth',
                });
                return;
            }
            if (this.mobile.length < 11) {
                this.$toast({
                    message: "请输入正确手机号",
                    icon: 'fail',
                    className: 'noticeWidths',
                });
                return;
            }
            //   if (!this.smsCodeNum || this.smsCodeNum.length !== 5) {
            //     this.$toast('请输入验证码')
            //    return
            //  }
            if (this.password === "") {
                this.$toast({
                    message: "请输入登录密码",
                    icon: 'fail',
                    className: 'noticeWidth',
                });
                return;
            }
            if (this.password < 6) {
                this.$toast({
                    message: "密码至少6位数",
                    icon: 'fail',
                    className: 'noticeWidth',
                });
                return;
            }

            if (!this.smsCodeNum || this.smsCodeNum.length !== 5) {
                this.$toast({
                    message: "请输入验证码",
                    icon: 'fail',
                    className: 'noticeWidth',
                })
                return
            }
            const param = {
                mobile: this.mobile,
                // password: this.password,
                newPassword: this.password,
                // code: this.code,
                //   open_id: this.open_id,
                mscode: this.smsCodeNum,
                // uuid: this.captchaData.uuid,
            };
            updatePassword(param).then(res => {
                if (res.code === 200) {
                  this.$dialog.alert({
                    message: res.message,
                    theme: 'round-button',
                    confirmButtonColor: 'green'
                  }).then(() => {
                    this.onClickLeft()
                  })
                } else {
                  this.$toast(res.message)
                }
              })
            },

        // },
        // async confirm(param) {
        //     this.$toast.loading({
        //         duration: 0,
        //         message: "提交中...",
        //         forbidClick: true,
        //         className: 'noticeWidth',
        //     });
        //     await this.$store.dispatch("user/forget", param).then((res) => {
        //         // console.log(res);
        //         this.$store.dispatch("user/info");
        //         this.$toast.clear();
        //         // this.$toast.success({ message: res.message, className: 'noticeWidths', });
        //         setTimeout(() => {
        //             this.login();
        //         }, 1500);

        //     }).catch(() => {
        //         this.$toast({
        //             message: '修改失败',
        //             icon: 'fail',
        //             className: 'noticeWidths',
        //         });
        //     });
        // },
        onClickLeft() {
            this.$router.go(-1);
        },
        login() {
            this.$router.push("/login");
            localStorage.setItem("hometype", "login");
        },
        getSmsCode() {
            if (this.smsCodeInterval !== null) {
                return;
            }
            if (!this.mobile || this.mobile.length !== 11) {
                this.$toast({
                    message: "请输入正确手机号",
                    icon: 'fail',
                    className: 'noticeWidths',
                });
                return;
            }
            getCode({ mobile: this.mobile }).then((res) => {
                if (res.code == 200) {
                    this.$toast({
                        message: "短信发送成功！请注意查收。",
                        icon: 'success',
                        className: 'noticeWidtht',
                    });
                    this.smsCodeText = this.smsCodeTime + "s 后重新获取";
                    this.smsCodeInterval = setInterval(() => {
                        this.smsCodeTime -= 1;
                        this.smsCodeText = this.smsCodeTime + "s 后重新获取";
                        if (this.smsCodeTime <= 0) {
                            this.smsCodeText = "发送验证码";
                            clearInterval(this.smsCodeInterval);
                            console.log(this.smsCodeInterval);
                        }
                    }, 1000);
                } else {
                    this.$toast({
                        message: res.message,
                        icon: 'fail',
                        className: 'noticeWidtht',
                    });
                }
            });
        },
        //密码显示与隐藏
        cheng() {
            if (this.passwordShow == false) {
                this.passwordShow = true
            } else {
                this.passwordShow = false
            }
        },
        chengs() {
            if (this.passwordShowtwo == false) {
                this.passwordShowtwo = true
            } else {
                this.passwordShowtwo = false
            }
        },
        loginss() {
            this.$router.push("/login");
        },
    },
};
</script>

<style lang="scss" scoped>
.login-wrap {
    background-size: 100% 100%;
    height: 100vh;
    position: relative;

    .login-body {
        position: absolute;
        transform: translate(-50%);
        left: 50%;
        top: 20px;

        .cent {
            text-align: center;
            margin-bottom: 100px;
        }

        .xg {
            width: 400px;
        }
    }

    .van-form {
        .van-field {
            width: 690px;
            height: 96px;
            border-radius: 40px;
            background: rgba(255, 255, 255, 1);
            line-height: 60px;
        }

        .success {
            width: 690px;
            height: 120px;
            border-radius: 100px;
            background: rgba(68, 159, 46, 1);
            border: none;
            font-size: 36px;
        }

        .btnss {
            width: 2rem;
            height: 60px;
            font-size: 24px;
        }
    }

    .down {
        font-size: 20px;
    }

    .van-cell {
        margin-bottom: 20px;
        border-radius: 5px;
    }
}
.amend-pass {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 40px 50px;
  .van-field {
    margin-top: 40px;
  }
  .van-button {
    margin-top: 40px;
    padding: 40px;
  }
}
.smsbtn{
  background-color: green;
  border-radius: 10px;
  height: 100px;
  height: 130px;
}
</style>
